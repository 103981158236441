<template>
  <div>
    <Top></Top>
    <!-- 首页头部 -->

    <div class="total">
      <div class="content">
        <div class="left">
          <img src="../../assets/index/login.png" alt="" />
        </div>
        <div class="store">
          <div class="shang">
            <viewer>
              <img :src="CompanyBriefInfo.image" alt="" />
            </viewer>
          </div>
          <router-link
            :to="{
              path: 'StoreHome',
              query: {
                id: searchdetailsdata.companyId,
                name: searchdetailsdata.companyName,
              },
            }"
            class="router"
          >
            <div class="flagshipe">
              {{ searchdetailsdata.companyName }}
            </div>
          </router-link>
          <div class="hong">
            <img src="../../assets/index/hong.png" />
            <div>尾易优选</div>
          </div>
        </div>
      </div>
    </div>
    <div class="goodmiddle">
      <div class="header">公告：交易之前请先签署电子合同，确保交易安全</div>
      <div class="aaa">
        <div class="line"></div>
        <div class="detail">商品详情</div>
      </div>
      <div class="bigdetail">
        <div>
          <div class="image">
            <viewer>
              <img :src="searchdetailsdata.mainimage" />
            </viewer>
            <div class="shenhea" v-if="commodstate == 0">待审核</div>
            <div class="shenhea" v-if="commodstate == 1">已通过</div>
            <div class="shenhea" v-if="commodstate == 2">已拒绝</div>
            <div class="shenhea" v-if="commodstate == 3">已下架</div>
            <div class="shenhea" v-if="commodstate == 4">已禁用</div>
          </div>
        </div>
        <div class="second">
          <div class="diyi">{{ searchdetailsdata.name }}</div>
          <div class="dier" v-if="searchdetailsdata.price == 0">
            <div>
              ￥<span>电议</span>
            </div>
          </div>
          <div class="dier" v-else>
            <div>
              ￥<span>{{ searchdetailsdata.price }}</span>/吨
            </div>
          </div>
          <nav class="nih">
            <div class="add">
              <div class="first">存放地：</div>
              <div>{{ searchdetailsdata.storageplace }}</div>
            </div>
            <div class="add">
              <div class="first">规格：</div>
              <div>{{ searchdetailsdata.specifications }}</div>
            </div>
            <!-- <div class="add">
              <div class="first">总重：</div>
              <div>{{ searchdetailsdata.totalprice }}</div>
            </div> -->
            <div class="add">
              <div class="first">数量：</div>
              <div>{{ searchdetailsdata.nubmer }} 吨</div>
            </div>
          </nav>
        </div>
        <div class="right"></div>
      </div>
    </div>

    <div class="goodbutton">
      <div class="buttfirst">
        <div v-if="imagesList"><i class="el-icon-arrow-left"></i></div>
        <div class="bfit" v-if="imagesList">
          <ul>
            <li>
              <viewer :images="imagesList">
                <img
                  v-for="(item, i) in imagesList"
                  :key="i"
                  :src="item"
                  class="img-thumb active"
                  data-v-78af6416=""
                  data-src=""
                  data-arg="undefined"
                />
              </viewer>
            </li>
          </ul>
        </div>
        <div v-if="imagesList"><i class="el-icon-arrow-right"></i></div>
      </div>
      <div class="buttsecondd">
        <div class="add">
          <div class="first">合计：</div>
          <div v-if="searchdetailsdata.price == 0">
            已选<span>{{ searchdetailsdata.nubmer }} </span
            >吨&nbsp;&nbsp;单价<span>电议 </span>
          </div>
          <div v-else>
            已选<span>{{ searchdetailsdata.nubmer }} </span
          >吨&nbsp;&nbsp;单价<span>{{ searchdetailsdata.price }} </span
          >/吨&nbsp;&nbsp;共<span>￥{{ searchdetailsdata.totalprice }}</span>
          </div>
        </div>
        <div class="didi" v-if="commodstate != 0">
          <el-button @click="editpub(id)">编辑</el-button>
        </div>
      </div>
    </div>

       <div class="butfooter">
      <div>
        <div class="add">
          <div class="first">品线</div>
          <div>{{ searchdetailsdata.productline }}</div>
        </div>
        <div class="add">
          <div class="first">品名</div>
          <div>{{ searchdetailsdata.productname }}</div>
        </div>
        <div class="add">
          <div class="first">材质</div>
          <div>{{ searchdetailsdata.material }}</div>
        </div>

        <div class="add">
          <div class="first">质量等级</div>
          <div>{{ searchdetailsdata.qualitylevel }}</div>
        </div>
        <div class="add">
          <div class="first">规格</div>
          <div>{{ searchdetailsdata.specifications }}</div>
        </div>
        <div class="add">
          <div class="first">仓库</div>
          <div>{{ searchdetailsdata.storageplace }}</div>
        </div>
         <div class="add">
          <div class="first">颜色</div>
          <div>{{ searchdetailsdata.color }}</div>
        </div>
        <div class="add">
          <div class="first">详细描述</div>
          <div>{{ searchdetailsdata.describe ? searchdetailsdata.describe :'无描述' }}</div>
        </div>
      </div>
      <div class="goodssa">
        <div class="add">
          <div class="first">涂油</div>
          <div>{{ searchdetailsdata.oil ? "有涂层" : "无涂层" }}</div>
        </div>
        <div class="add">
          <div class="first">锌层</div>
          <div>{{ searchdetailsdata.zinclayer ? '有' : '无'}}</div>
        </div>
        <div class="add">
          <div class="first">有花无花</div>
          <div>{{ searchdetailsdata.pattern ? '有' : '无'}}</div>
        </div>
        <div class="add">
          <div class="first">表面工艺</div>
          <div>{{ searchdetailsdata.surfaceprocess ? searchdetailsdata.surfaceprocess  : '无'}}</div>
        </div>
        <div class="add">
          <div class="first">保证书</div>
          <div>{{ searchdetailsdata.warranty ? '有' : '无' }}</div>
        </div>
        <div class="add">
          <div class="first">钢厂</div>
          <div>{{ searchdetailsdata.steelmill }}</div>
        </div>

        <div class="add">
          <div class="first">出厂时间</div>
          <div>  {{searchdetailsdata.manufacturedate ? $moment(searchdetailsdata.manufacturedate).format("YYYY年MM月DD日 ") : ''}}</div>
        </div>
      </div>
    </div>
    <Public
      :totaldialog="totaldialog"
      @clears="clears"
      @surees="surees"
      :tabIndex="tabIndex"
      :goodid="goodid"
      ref="mychild"
    ></Public>
    <Footer></Footer>
  </div>
</template>
<script>
import { Message } from "element-ui";
import Top from "@/components/login/Top.vue";
import Footer from "@/components/login/Footer.vue";
import Public from "../center/entre/public.vue";
import goodsApi from "../../api/goodsApi";
import tradingApi from "../../api/tradingApi";
export default {
  data() {
    return {
      tabIndex: 1,
      editform: {},
      totaldialog: false,
      //  企业信息
      CompanyBriefInfo: "",
      // 企业id
      companyIds: "",
      // 四个状态
      commodstate: null,
      imagesList: "",
      liability: false,
      contract: false,
      confirmed: false,
      goodid: "",
      order: false,
      searchgoodsinput: "",
      id: this.$route.query.id,
      searchdetailsdata: "",
      options: [],
      value: "1",
      num: 1,
      a: "el-icon-arrow-down",
      start: true,
      idear: false,
    };
  },
  components: {
    Top,
    Footer,
    Public,
  },
  mounted() {
    this.goodsdetails();
  },

  methods: {
    clears(bool) {
      this.totaldialog = bool;
      //  this.addDialog = !this.addDialog
    },
    surees(bool) {
      this.totaldialog = bool;
      //  this.addDialog = !this.addDialog
    },
    // 编辑
    editpub(id) {
      // this.addDialog = false;
      this.goodid = id;

      goodsApi.getGoodsInfo({ id: this.goodid }, (res) => {
        if (res.data.code != 200) {
          return Message.error(res.data.message);
        }
        this.editform = res.data.result;

        this.$refs.mychild.parentHandleclick(this.editform);
        this.$refs.mychild.gFirstInstitution(this.editform.steelmill);
      });
      this.totaldialog = true;
      this.$store.commit("intaddpublic", false);
    },
    // 获取商品详情页数据
    goodsdetails() {
      goodsApi.goodsdetails({ id: this.id }, (res) => {
        this.commodstate = res.data.result.state.code;
        if (res.data.code != 200) {
          Message.error(res.data.message);
        } else {
          this.searchdetailsdata = res.data.result.data;

          if (res.data.result.data.image) {
            this.imagesList = res.data.result.data.image;
          }
          this.companyIds = res.data.result.data.companyId;

          // 获取当前企业信息
          tradingApi.getCompanyBriefInfo(
            { companyId: this.companyIds },
            (res) => {
              this.CompanyBriefInfo = res.data.result;
            }
          );
        }
      });
    },

    handleChange(value) {},
    hui() {
      this.start = false;
      this.idear = true;
    },
    red() {
      this.start = true;
      this.idear = false;
    },
    opens() {
      this.liability = !this.liability;
    },
    close() {
      this.liability = !this.liability;
    },
    contractclose() {
      this.contract = !this.contract;
    },
    issure() {
      this.liability = !this.liability;
      this.contract = !this.contract;
    },
    sendcontract() {
      this.contract = !this.contract;
      this.confirmed = !this.confirmed;
    },
    confirmedclose() {
      this.confirmed = !this.confirmed;
    },
    issurechange() {
      this.confirmed = !this.confirmed;
      this.order = !this.order;
    },
    orderclose() {
      this.order = !this.order;
    },
    orderissure() {
      this.order = !this.order;
    },
  },
};
</script>
<style lang='scss'>
.router {
  text-decoration: none;
}
.confirmed,
.order {
  .color {
    color: #999999;
  }

  .toice {
    text-align: center;
    color: #3b4144;
  }

  .mertext1 {
    width: 500px;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 65px;
    color: #999999;
    font-size: 14px;
  }

  .bbb {
    display: flex;

    .quxiao {
      width: 100px;
      height: 40px;
      font-size: 15px;
      color: #dd524d;
      background-color: white;
      margin-left: 100px;
      margin-top: 30px;
      margin-bottom: 20px;
      border-radius: 20px;
      border: 1px solid #dd524d;
    }

    .issure {
      width: 160px;
      height: 40px;
      font-size: 15px;
      color: #dd524d;
      background-color: white;
      margin-left: 80px;
      margin-top: 30px;
      margin-bottom: 20px;
      border-radius: 20px;
      border: 1px solid #dd524d;
    }

    .quxiao:hover,
    .issure:hover {
      color: white;
      background-color: #dd524d;
    }
  }
}
.store {
  // width: 320px;
  display: flex;
  .hong {
    width: 90px;
    line-height: 32px;
    display: flex;
    color: #e94f55;
    margin-left: 21px;
    img {
      width: 14px;
      height: 14px;
      position: relative;
      top: 9px;
    }
    div {
      margin-left: 6px;
    }
  }
  .shang {
    margin-left: 50px;
    img {
      width: 50px;
      height: 40px;
      margin-right: 10px;
    }
  }
  .flagshipe {
    color: #333333;
    font-size: 14px;
    line-height: 32px;
    // margin-left: -15px;
  }
}

.contract {
  .heada {
    display: flex;
    margin-left: 65px;
    margin-top: 5px;
    width: 400px;
    .tanhao {
      color: white;
      background-color: #ff8c00;
      width: 15px;
      height: 15px;
      line-height: 15px;
      text-align: center;
      border-radius: 50%;
      font-size: 10px;
      margin-top: 3px;
    }

    .cont {
      color: #999999;
      font-size: 13px;
      font-weight: 400;
      height: 30px;
      margin-left: 3px;
    }
  }

  .color {
    color: #999999;
  }

  .merimage {
    width: 35px;
    height: 35px;
    position: relative;
    top: -13px;
    left: 322px;
  }

  .toice {
    text-align: center;
    color: #3b4144;
  }

  .issure {
    width: 160px;
    height: 40px;
    font-size: 15px;
    color: white;
    background-color: #dd524d;
    margin-left: 170px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 20px;
    border: 0px solid;
  }

  .mertext1 {
    width: 500px;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 65px;
    color: #999999;
    font-size: 14px;

    .buttt {
      display: flex;
      flex-wrap: wrap;

      button {
        border: 1px solid #e94f55;
        border-radius: 2px;
        background-color: white;
        height: 22px;
        width: 75px;
        text-align: center;
        color: #e94f55;
        line-height: 20px;
        font-size: 12px;
        margin-left: 15px;
        margin-top: 10px;
      }

      button:hover {
        background-color: #e94f55;
        color: white;
      }
    }

    .total {
      width: 77px;
      height: 104px;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-left: 15px;

      .wai {
        height: 82px;
        width: 77px;
        background-color: rgba(0, 0, 0, 0.1);
      }

      .nei {
        height: 78px;
        margin: 0px auto;
        width: 68px;
        background-color: white;
        position: relative;
        top: 10px;
      }

      .add {
        text-align: center;
        font-size: 12px;
        height: 22px;
        line-height: 22px;
      }
    }
  }
}

.popup {
  .toice {
    text-align: center;
    color: #3b4144;
  }

  .mertext1 {
    width: 400px;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 50px;
    color: #999999;
    font-size: 14px;
  }
  .issure {
    width: 160px;
    height: 40px;
    font-size: 15px;
    color: white;
    background-color: #dd524d;
    margin-left: 170px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 20px;
    border: 0px solid;
  }
}

.total {
  border-bottom: 1px solid #e95157;
}
.content {
  width: 60%;
  height: 99px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 740px;
  .left {
    box-sizing: border-box;
    width: 200px;
    // padding: 0px 13px 0px 14px;
    //  border: 1px solid red;
    img {
      width: 140px;
      height: 70px;
      vertical-align: middle;
      //   padding-left: 30px;
      // border: 1px solid red;
    }
  }
  .center {
    width: 700px;
    box-sizing: content-box;
    margin-left: 46px !important;
    height: 40px;
    border-radius: 3px;
    border: 2px solid #e94f55;
    display: flex;
    justify-content: start;
    flex-wrap: nowrap;
    .el-input {
      border: none;
    }

    .el-button--danger {
      border-radius: 0px;
      font-size: 16px;
      color: #e94f55ff;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.goodmiddle {
  width: 60%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  min-width: 740px;
  .nih {
    margin-top: 20px;
  }
  .header {
    width: 99%;
    background: #e94f55;
    height: 20px;
    color: white;
    padding-left: 10px;
    line-height: 20px;
    margin-top: 15px;
  }
  .aaa {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    .line {
      width: 3px;
      height: 18px;
      background: #e94f55;
      border-radius: 2px;
      margin-top: 6px;
    }
    .detail {
      width: 128px;
      height: 26px;
      font-size: 20px;
      font-weight: 600;
      color: #e94f55;
      margin-left: 20px;
    }
  }
  .bigdetail {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    .image {
      img {
        width: 235px;
        height: 235px;
        background: #333333;
      }
    }
    .shenhea {
      color: white;
      width: 235px;
      height: 30px;
      background: rgba(0, 0, 0, 0.6);
      position: relative;
      top: -32px;
      left: 0px;
      text-align: center;
      line-height: 30px;
    }
  }
  .second {
    width: 50%;
    margin-left: 20px;
    .diyi {
      color: rgba(0, 0, 0, 0.87);
      font-size: 20px;
      font-weight: bold;
    }
    .dier {
      margin-top: 20px;
      .nowmoney {
        font-size: 10px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.56);
      }
    }
    .dier div:nth-of-type(1) {
      color: #e94f55;
      font-weight: bold;
      font-size: 16px;
    }
    .dier div:nth-of-type(1) span {
      font-size: 20px;
      font-weight: bold;
    }
    .add {
      display: flex;
      flex-direction: row;
      height: 25px;
      .first {
        width: 86px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.56);
      }
    }
    .add div:nth-of-type(2) {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .right {
    width: 25%;
    .number {
      font-size: 16px;
      color: #999999;
      margin-top: 170px;
      float: right;
    }
    .hui,
    .red {
      width: 25px;
      height: 25px;
      float: right;
    }
  }
}
.goodbutton {
  width: 60%; //   margin: 0 auto;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  min-width: 740px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
  .add {
    display: flex;
    flex-direction: row;
    height: 30px;
    //   line-height: 80px;
    .first {
      width: 86px;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.56);
    }
  }
  .add div:nth-of-type(2) {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
  }
  .add div:nth-of-type(2) span {
    color: #e94f55;
  }
  .buttfirst {
    width: 235px;

    display: flex;
    flex-direction: row;
    .el-icon-arrow-left,
    .el-icon-arrow-right {
      line-height: 70px;
      font-weight: bolder;
    }
    .bfit img {
      width: 70px;
      height: 70px;
      margin-left: 5px;
    }
    ul {
      width: 215px;
      display: flex;
      flex-direction: row;
    }
    ul li {
      list-style: none;
    }
  }
  .buttsecondd {
    width: 896px;
    margin-left: 90px;
  }
  .didi {
    margin-top: 10px;
    .el-button {
      line-height: 8px;
      width: 282px;
      height: 40px;
      border-radius: 5px;
      border: 2px solid #e94f55;
      color: #e94f55;
    }
    .el-button:hover {
      background: #e94f55;
      color: white;
    }
  }
  .tishi {
    font-size: 8px !important;
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.56);
  }
}
.butfooter {
  width: 60%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  min-width: 740px;
  //   margin-top: 20px;
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  margin-bottom: 30px;
  .add {
    display: flex;
    flex-direction: row;
    height: 30px;
    line-height: 80px;
    .first {
      width: 100px;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.56);
    }
  }
  .add div:nth-of-type(2) {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
  }
  .goodssa {
    width: 600px;
  }
}
</style>
